.single img {
    width: auto;
    height: 340px;
    object-fit: contain;
    max-width: 100%;
    transition: 0.7s;
}

.sliderWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 990;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.fullScreenImage img {
    min-width: 100px;
    width: auto;
    max-width: 90vw;
    height: auto;
    max-height: 90vh;
}

.nextSlide {
    top: 50%;
    right: 20px;
}

.prevSlide {
    top: 50%;
    left: 20px;
}

.closeSlide {
    top: 40px;
    right: 40px;
}