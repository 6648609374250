@import url('https://fonts.googleapis.com/css2?family=Sassy+Frass&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Corinthia&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', monospace;
}

code {
  font-family: 'Inconsolata', monospace;
}
