.imageStyle {
    max-width: 100%;
    height: 340px;
    object-fit: contain;
    transition: 1.2s;
    transform: scale(1);
}

.imageStyle:hover {
    transform: scale(1.05);
}